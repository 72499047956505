import { Card } from "react-bootstrap"
import { motion } from "framer-motion"
import { FormattedMessage } from "react-intl"

import MiracumLogo from "../../resources/media/logos/miracum-logo.png"
import MIILogo from "../../resources/media/logos/mii-logo.png"
import AICareLogo from "../../resources/media/logos/ai-care-logo.jpg"
import FDPGLogo from "../../resources/media/logos/fdpg-logo.png"
import DistralLogo from "../../resources/media/logos/distral-logo.png"
import DigitalesHessenLogo from "../../resources/media/logos/digitales-hessen-logo.png"
import DIZLogo from "../../resources/media/logos/diz-logo.jpg"
import NUMLogo from "../../resources/media/logos/num-logo.svg"

import "../../css/App.css"
import "../../css/content/About.css"

function About() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <h1 className="contentHeadline"><FormattedMessage id="about.headline" /></h1>
      <Card style={{ width: "100%", border: "0", marginTop: "40px" }}>
        <Card.Body>
          <Card.Title>
            <h3><FormattedMessage id="about.subheadline" /></h3>
          </Card.Title>
          <Card.Text>
            <FormattedMessage id="about.description" />
          </Card.Text>
        </Card.Body>
      </Card>
      <Card style={{ width: "100%", border: "0" }}>
        <Card.Body>
          <Card.Title className="contentSubheadline">
            <FormattedMessage id="about.projectsSubheadline" />
          </Card.Title>
          <div className="contentRow">
            <Card className="contentCard">
              <Card.Body>
                <Card.Title>
                  <a
                    href="https://digitales.hessen.de/"
                    target="_blank"
                    rel="noreferrer"
                  > 
                    <img
                        src={DigitalesHessenLogo}
                        alt="Digitales Hessen Logo"
                        className="partnerLogo digitalesHessenLogo"
                    />
                  </a>
                  <a
                    href="https://www.lidia-hessen.de/projekte-entdecken/health-data-management-platform-healthdmp/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={DistralLogo}
                      alt="Distral Logo"
                      className="partnerLogo distralLogo"
                    />
                  </a>
                </Card.Title>
                <Card.Text>
                    <FormattedMessage id="about.projectHealthDMP" />
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="contentCard">
              <Card.Body>
                <Card.Title>
                  <a
                    href="https://ai-care-cancer.de"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={AICareLogo}
                      alt="AI-CARE Logo"
                      className="partnerLogo aicareLogo"
                    />
                  </a>
                </Card.Title>
                <Card.Text>
                  <FormattedMessage id="about.projectAICare" />
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </Card.Body>
      </Card>
      <Card style={{ width: "100%", border: "0" }}>
        <Card.Body>
          <Card.Title className="contentSubheadline">
            <FormattedMessage id="about.partnersSubheadline" />
          </Card.Title>
          <div className="contentRow">
            <Card className="contentCard">
              <Card.Body>
                <Card.Title>
                  <a href="https://www.miracum.org" target="_blank" rel="noreferrer">
                    <img
                      src={MiracumLogo}
                      alt="Miracum Logo"
                      className="partnerLogo miracumLogo"
                    />
                  </a>
                </Card.Title>
                <Card.Text>
                  <FormattedMessage id="about.partnerMiracum" />
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="contentCard">
              <Card.Body>
                <Card.Title>
                  <a href="https://www.medizininformatik-initiative.de/de/konsortien/datenintegrationszentren" target="_blank" rel="noreferrer">
                    <img
                      src={DIZLogo}
                      alt="DIZ Logo"
                      className="partnerLogo dizLogo"
                    />
                  </a>
                </Card.Title>
                <Card.Text>
                  <FormattedMessage id="about.partnerDIZ" />
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="contentRow">
            <Card className="contentCard">
              <Card.Body>
                <Card.Title>
                <a
                  href="https://www.medizininformatik-initiative.de"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={MIILogo} alt="MII Logo" className="partnerLogo" />
                </a>
                </Card.Title>
                <Card.Text>
                  <FormattedMessage id="about.partnerMII" />
                </Card.Text>
              </Card.Body>
            </Card>
            <Card style={{ flex: "50%", border: "0", marginTop: "20px" }}>
              <Card.Body>
                <Card.Title>
                <a
                  href="https://forschen-fuer-gesundheit.de"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={FDPGLogo} alt="FDPG Logo" className="partnerLogo" />
                </a>
                </Card.Title>
                <Card.Text>
                  <FormattedMessage id="about.partnerFDPG" />
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="contentRow">
            <Card className="contentCard">
              <Card.Body>
                <Card.Title>
                <a
                  href="https://www.netzwerk-universitaetsmedizin.de"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={NUMLogo} alt="NUM Logo" className="partnerLogo" />
                </a>
                </Card.Title>
                <Card.Text>
                  <FormattedMessage id="about.partnerNUM" />
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </Card.Body>
      </Card>
    </motion.div>
  )
}

export default About
